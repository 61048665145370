import React from 'react';
import LogoImage  from '../../assets/logo/mci.png';
import { Redirect, useLocation } from 'react-router-dom';
import { Input, Button, Alert, Form } from 'antd';
import { useAuthContext } from '../../context/authContext';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const BaseSignInForm = () => {
  let location = useLocation();
  const {
    doSignInWithEmailAndPassword,
    redirectToReferrer,
    loading,
    error,
    setError,
  } = useAuthContext();

  const onFinish = (values) => {
    console.log('CLICKED', values);
    doSignInWithEmailAndPassword(values.email, values.password);
  };

  const { from } = location.state || { from: { pathname: '/' } };
  if (redirectToReferrer) return <Redirect to={from} />;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        minHeight: '100vh',
      }}
    >
      <div
        style={{
          width: '30%',
          padding: '0 20px 0',
          paddingTop: '24vh',
        }}
      >
        <div 
          style={{
            backgroundColor: "white",
            padding: "20px"
          }}>
          <div style={{ width: '100%', textAlign: 'center', paddingBottom: '20px'}}>
            <img src={LogoImage} alt="logo"/>
            {/*<Logo
              className="hide-logo-on-keyboard"
              style={{ height: '64px', marginBottom: '20px' }}
            />*/}
          </div>
          <Form onFinish={onFinish}>
            {error && (
              <Alert
                message="Your account or password is incorrect."
                type="error"
                showIcon
                closable
                onClose={() => setError(null)}
              />
            )}
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'E-mail not valid!',
                },
              ]}
            >
              <Input
                prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="E-mail"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rule={[
                {
                  required: true,
                  type: 'password',
                  message: 'Please enter password!',
                },
              ]}
            >
              <Input
                type="password"
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item colon={false}>
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{ width: '100%' }}
              >
                Sign in
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default BaseSignInForm;
