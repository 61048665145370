import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { FileOutlined, HomeOutlined, ScheduleOutlined, UserOutlined } from '@ant-design/icons';
import { Layout, Menu, Avatar } from 'antd';
import { StyledMenu } from './style';
import Logo from '../Logo';
import { useAuthContext } from '../../context/authContext';
import { auth } from 'firebase';

const { Header } = Layout;
const { SubMenu } = Menu;

const Navbar = () => {
  const location = useLocation();
  const history = useHistory();
  //style={{position: 'fixed', zIndex: 1, width: '100%', top: 0}}
  const { authUser, doSignOut } = useAuthContext();
  const handleSignOut = (e) => {
    doSignOut();
  };
  const hidden = authUser ? false : true;
  return (
    <Header>
      <StyledMenu
        onSelect={({ key }) => history.push(key)}
        selectedKeys={[location.pathname]}
        theme="dark"
        mode="horizontal"
      >
        <Logo />
        <Menu.Item key="/" hidden={hidden}>
          <HomeOutlined />
          <span>Home</span>
        </Menu.Item>
        <Menu.Item key="/order" hidden={hidden}>
          <FileOutlined />
          <span>Orders</span>
        </Menu.Item>
        <Menu.Item key="/condition" hidden={hidden}>
          <ScheduleOutlined />
          <span>Conditions</span>
        </Menu.Item>
        <SubMenu
          key="user"
          style={{float: 'right'}}
          hidden={hidden}
          title={
            <span>
              {/*<UserOutlined />*/}
              <Avatar 
                size="large"
                style={{
                  backgroundColor: "#ffbf00",
                }}> 
                {authUser && authUser.email.substring(0, 1).toUpperCase()}
              </Avatar>
            </span>
          }
        >
          <Menu.Item onClick={handleSignOut}>Sign out</Menu.Item>
        </SubMenu>
      </StyledMenu>
    </Header>
  );
};

export default Navbar;
