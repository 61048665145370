import React from 'react';

import InfoStudioLogo from './infostudio-logo.png';
import { FooterWrapper } from './style';

const Footer = () => {
  return (
    <FooterWrapper>
      <div>
        <img src={InfoStudioLogo} alt="logo" />
      </div>
      Copyright © 2020 Info Studio d.o.o. All Rights Reserved.
    </FooterWrapper>
  );
};

export default Footer;
