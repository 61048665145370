import React, { useState, useEffect, useContext, createContext } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { useApolloClient } from '@apollo/react-hooks';
import gql from 'graphql-tag';

// note: Ovdje je zaostao google analytics od ranije, a na firebase-u ovog projekta nismo enable GA.

console.log(process.env.REACT_APP_API_KEY, 'API KEY');
firebase.initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
});

/*const GET_USER = gql`
  query GetSignedInUser($firebase_uid: String) {
    user(firebase_uid: $firebase_uid) {
      id
      name
      code
      location
      email
      role
      organization {
        id
        name
        code
      }
      subordinates {
        id
        name
      }
    }
  }
`;*/

export const Context = createContext();

export const Provider = ({ children }) => {
  const apolloClient = useApolloClient();
  const [authUser, setAuthUser] = useState(null);
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const clearState = () => {
    setError(null);
    setRedirectToReferrer(false);
    setAuthUser(null);
  };

  const doSignInWithEmailAndPassword = async (email, password) => {
    try {
      setLoading(true);
      await firebase.auth().signInWithEmailAndPassword(email, password);
    } catch (error) {
      console.error('THIS IS A PROBLEM', error.message);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const doSignOut = async () => {
    await firebase.auth().signOut();
    clearState();
  };

  const doPasswordReset = async (email) => {
    return await firebase.auth().sendPasswordResetEmail(email);
  };

  /*useEffect(() => {
    const mergeDbAndFbUser = async () => {
      const { data, error } = await apolloClient.query({
        //query: GET_USER,
        variables: { firebase_uid: authUser.uid },
        fetchPolicy: 'network-only',
      });
      if (error) setError(error);

      if (data && data.user)
        setAuthUser((fbUser) => {
          return { ...data.user, ...fbUser };
        });
    };

    if (authUser && !authUser.organization) {
      mergeDbAndFbUser();
    }
  }, [authUser, setAuthUser, apolloClient]);*/

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (fbUser) => {
      if (fbUser) {
        setAuthUser(fbUser);
        setRedirectToReferrer(true);
      } else {
        clearState();
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Make the context object:
  const authContext = {
    authUser,
    redirectToReferrer,
    loading,
    error,
    doSignInWithEmailAndPassword,
    doSignOut,
    doPasswordReset,
    setError,
  };

  // pass the value in provider and return
  return <Context.Provider value={authContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;

export const useAuthContext = () => useContext(Context);
