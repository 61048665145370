import React from 'react';
import * as firebase from 'firebase/app';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';

// note: auth token se kroz apollo context salje uz request na backend

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  request: async (operation) => {
    try {
      const currentUser = firebase.auth().currentUser;
      let token = '';
      if (currentUser) {
        token = await currentUser.getIdToken(true);
      }

      operation.setContext({
        headers: {
          authorization: token,
        },
      });
    } catch (error) {
      console.error(error);
    }
  },
  onError: ({ graphQLErrors, networkError }) => {
    console.log('ERROR HAPPENED');
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );

    if (networkError) console.log(`[Network error]: ${networkError}`);
  },
});

const Provider = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default Provider;
