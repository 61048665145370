import React from 'react';
import { Provider as OrderProvider } from './OrderContext';
import { Provider as AuthProvider } from './authContext';

// note: Context nakon sto se deklarise, mora se deklarisati i njegov provider da bi se mogao koristiti
// Ovdje je nedostajao AuthProvider

const Provider = ({ children }) => {
  return (
    <AuthProvider>
      <OrderProvider>{children}</OrderProvider>
    </AuthProvider>
  );
};

export default Provider;
