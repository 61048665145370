import React, { createContext, useContext, useState } from 'react';

export const Context = createContext({});

export const Provider = (props) => {
  // Initial values are obtained from the props
  const { children } = props;

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal((s) => !s);
  // Make the context object:
  const context = {
    showModal,
    toggleModal,
  };

  // pass the value in provider and return
  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export const { Consumer } = Context;

export const useOrderContext = () => useContext(Context);
