import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import SignInPage from './components/SignIn';
import Spinner from './components/Spinner';
import PrivateRoute from './components/PrivateRoute';

// note:
// Lazy komponente koristimo da bi ubrzali prvi load stranice / time-to-paint. Jer je react ustvari single page app,
// ako imamo 50 page-ova, da ne bi korisnik cekao dugo dok se svih 50 download-a i tek onda vidio prvi put sign in page ili home page
// mozemo napraviti da su komponente lazy i da se "skinu" sa servera tek kada korisnik navigira na njihovu rutu.
// Dok se radi download stranice sa server-a, korisnik vidi komponentu u fallback-u od Suspensa.

// Ovdje si imao problem sto si napravio da je Home lazy, ali i njegov fallback Spinner je bio lazy. Slicno i sa PrivateRoute komponentom.

const Home = lazy(() => import('./views/Home'));
const Order = lazy(() => import('./views/Order'));
const Items = lazy(() => import('./views/Items'));
const Condition = lazy(() => import('./views/Condition'));

const LazyComponent = ({ component: Component, props }) => {
  return (
    <Suspense fallback={<Spinner />}>
      <Component {...props} />
    </Suspense>
  );
};

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/sign-in" component={SignInPage} />
      <PrivateRoute
        exact
        path="/"
        component={() => <LazyComponent component={Home} />}
      />

      <PrivateRoute
        exact
        path="/order"
        component={() => <LazyComponent component={Order} />}
      />
      <PrivateRoute
        exact
        path="/items"
        component={() => <LazyComponent component={Items} />}
      />
      <PrivateRoute
        exact
        path="/condition"
        component={() => <LazyComponent component={Condition} />}
      />
      <Redirect from="*" to="/sign-in" />
    </Switch>
  );
};

export default Routes;
